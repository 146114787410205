'use strict';

angular.module('kljDigitalLibraryApp')
    .service('OrderService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var subscriptionBaseUrl = '/api/subscription';
            var orderBaseUrl = '/api/order';


            this.getPrice = function(payload) {

                return common.callApi('POST',
                    subscriptionBaseUrl + '/getPrice',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload)

                .then(function(response) {

                    return response.data;
                })
            }

            this.get = function(id) {
                return common.callApi('GET',
                    orderBaseUrl + '/get/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE, {})

                .then(function(response) {

                    console.log("response in get order-->", response);
                    return response.data;
                })
            }

            this.subcriptionOrderDetails = function(id) {
                return common.callApi('GET',
                    orderBaseUrl + '/getOrder/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE, {})

                .then(function(response) {

                    console.log("response in get order-->", response);
                    return response.data;
                })
            }
        }
    ]);